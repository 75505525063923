import React from 'react'

export class Header extends React.Component {
  render() {
    return (
        <div
            className="full-width-image-container margin-top-0"
            style={{
                backgroundImage: `url(${
                    !!this.props.image.childImageSharp ? this.props.image.childImageSharp.fluid.src : this.props.image
                })`,
                backgroundPosition: `top left`,
                backgroundAttachment: `fixed`,
            }}
        >
            <div
                style={{
                    display: 'flex',
                    height: '150px',
                    lineHeight: '1',
                    justifyContent: 'space-around',
                    alignItems: 'left',
                    flexDirection: 'column',
                }}
            >
                <h1
                    className="has-text-centered has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                    style={{
                        boxShadow: 'rgb(183, 119, 41) 0.5rem 0px 0px, rgb(183, 119, 41) -0.5rem 0px 0px',
                        backgroundColor: 'rgb(183, 119, 41)',
                        color: 'white',
                        lineHeight: '1',
                        padding: '0.25em',
                    }}
                >
                    {this.props.title}
                </h1>
                { (this.props.subheading.length > 0) && (
                <h3
                    className="has-text-centered has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
                    style={{
                        boxShadow: 'rgb(183, 119, 41) 0.5rem 0px 0px, rgb(183, 119, 41) -0.5rem 0px 0px',
                        backgroundColor: 'rgb(183, 119, 41)',
                        color: 'white',
                        lineHeight: '1',
                        padding: '0.25em',
                    }}
                >
                    {this.props.subheading}
                </h3>
                ) }
            </div>
        </div>
    )
  }
};

export default Header;