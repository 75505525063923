import React from 'react';
import { navigate } from 'gatsby-link';
// import GoogleMapReact from 'google-map-react';
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import { default as mapboxgl } from 'mapbox-gl';
import Layout from '../../components/Layout';
import { Header } from '../../components/Header';

mapboxgl.accessToken = 'pk.eyJ1IjoiZGFuYnVrIiwiYSI6ImNraWoyam9kdTFpeHgyeXF1YThreDFqdTUifQ.r-xXR8NIkmR1qiYHDYn8Gg';

const AnyReactComponent = ({ text }) => <div>{text}</div>;

const containerStyle = {
  width: '600px',
  height: '400px'
};
 
const center = {
  lat: 51.517573,
  lng: -0.206293
};

class MyMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 51.51754,
      lng: -0.20626,
      zoom: 12
    }
  }


  componentDidMount() {
    const point = new mapboxgl.LngLat(this.state.lng, this.state.lat);
    const map = new mapboxgl.Map({
      container: this.mapContainer,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: point,
      zoom: this.state.zoom
    });
    /* const marker = new mapboxgl.Marker()
      .setLngLat(point)
      .addTo(map); */
  }

  render() {
    return (
      <a href={`geo:${this.state.lat},${this.state.lng}`}>
        <div ref={el => this.mapContainer = el} style={ { height: '300px' }} />
      </a>
    )
  }
}



export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <Layout>
        <Header title="Contact" subheading="" image="/img/kailash-three-bottles.webp" />
        <div className="container">
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <div className="section">
            <h2 className="title is-size-3 has-text-weight-bold is-bold-light">
              Contact
            </h2>
            <div className="content columns">
              <div className="column">
                <div>Address:<br /><span style={ { marginLeft: '1em' } }>Portobello Road Market.</span><br/><span style={ { marginLeft: '1em' } }>London,</span><br/><span style={ { marginLeft: '1em' } }>NW11 1AN</span></div>
                <div>Telephone:<br /><span style={ { marginLeft: '1em' } }><a href="tel:+447412120155">+44 7412 120155</a></span></div>
                <div>email:<br /><span style={ { marginLeft: '1em' } }><a href="info@kailash.org.uk">info@kailash.org.uk</a></span></div>
              </div>
              <div className="column">
                <span>Opening Times:<br />
                <span style={ { marginLeft: '1em' } }>Fri: 10am - 5pm</span><br />
                <span style={ { marginLeft: '1em' } }>Sat: 10am - 5pm</span><br />
                <br />
                <span>Our stall position changes every market so we could be anywhere on Portobello Road, please ring us on the day for our exact location.</span>
                </span>
              </div>
            </div>
            <div className="section">
              <MyMap/>
            </div>
          </div>
        </div>
        </div>
        </div>
      </Layout>
    )
  }
}
